import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import {Breadcrumb} from 'react-bootstrap';
import {render} from 'react-dom';
import {Link} from 'react-router-dom';
import '../../style.css';
import axios from 'axios';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import {API_URL} from '../../http';
import { useParams } from 'react-router-dom';

function EditGamePage() {
  const [items, setItems] = useState([]);
  const [validated, setValidated] = useState(false);
  const {id} = useParams();
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState('');
  const [autor, setAutor] = useState('');
  const [special, setSpecial] = useState('');
  const [cost, setCost] = useState('');
  const [published, setPublished] = useState('false');
  const [imgTitle, setImgTitle] = useState('Выбрать файл...');
  const {store} = useContext(Context);
  const [costSmall, setCostSmall] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountSmall, setDiscountSmall] = useState('');
  const [typeGame, setTypeGame] = useState('');
  const [maxPlayers, setMaxPlayers] = useState('');
  const [age, setAge] = useState('');
  const [trailer, setTrailer] = useState(null);
  const [pic1, setPic1] = useState(null);
  const [pic2, setPic2] = useState(null);
  const [pic3, setPic3] = useState(null);
  const [pic4, setPic4] = useState(null);
  const [pic5, setPic5] = useState(null);

  const [oldImg, setOldImg] = useState('');
  const [oldTrailer, setOldTrailer] = useState('');
  const [oldPic1, setOldPic1] = useState('');
  const [oldPic2, setOldPic2] = useState('');
  const [oldPic3, setOldPic3] = useState('');
  const [oldPic4, setOldPic4] = useState('');
  const [oldPic5, setOldPic5] = useState('');


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    addPages();
  };

  const page = {id};

  const getOneNews = async () => {
      const res = await fetch(
        API_URL + `/g/${id}`
      );
      const data = await res.json();
      if(data != null) {
          setTitle(data.title);
          setDescription(data.description);
          setPublished(data.published);
          setTypeGame(data.typeGame);
          setMaxPlayers(data.maxPlayers);
          setCost(data.cost);
          setAge(data.age);
          setCostSmall(data.costSmall);
          setDiscount(data.discount);
          setDiscountSmall(data.discountSmall);
          setOldImg(data.img);
          setOldTrailer(data.trailer);
          setOldPic1(data.pic1);
          setOldPic2(data.pic2);
          setOldPic3(data.pic3);
          setOldPic4(data.pic4);
          setOldPic5(data.pic5);
      }

      setItems(data);
    };


  const selectFile = e => {
      setFile(e.target.files[0])
      if (e.target.files[0] == null){
          setImgTitle(null);
      }
      else setImgTitle(e.target.files[0].name);
  }

  const selectTrailer = e => {
    setTrailer(e.target.files[0])
  }

  const selectPic1 = e => {
    setPic1(e.target.files[0])
  }
  const selectPic2 = e => {
    setPic2(e.target.files[0])
  }
  const selectPic3 = e => {
    setPic3(e.target.files[0])
  }
  const selectPic4 = e => {
    setPic4(e.target.files[0])
  }
  const selectPic5 = e => {
    setPic5(e.target.files[0])
  }

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  const addPages = () => {
      if (title == '' || description == '' || typeGame == '' || maxPlayers == '') {
          return errorNotify(`Необходимо заполнить все поля!`);
      }
      if (file == null) {
          setFile(null);
      }
      const formData = new FormData()
      formData.append('title', title)
      formData.append('img', file)
      formData.append('trailer', trailer)
      formData.append('age', age)
      formData.append('pic1', pic1)
      formData.append('pic2', pic2)
      formData.append('pic3', pic3)
      formData.append('pic4', pic4)
      formData.append('pic5', pic5)
      formData.append('oldImg', oldImg)
      formData.append('oldTrailer', oldTrailer)
      formData.append('oldPic1', oldPic1)
      formData.append('oldPic2', oldPic2)
      formData.append('oldPic3', oldPic3)
      formData.append('oldPic4', oldPic4)
      formData.append('oldPic5', oldPic5)
      formData.append('description', description)
      formData.append('typeGame', typeGame)
      formData.append('maxPlayers', maxPlayers)
      formData.append('cost', cost)
      formData.append('costSmall', costSmall)
      formData.append('discount', discount)
      formData.append('discountSmall', discountSmall)
      formData.append('published', published)
      axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      axios.post(API_URL + '/g/edit/' + id, formData)
      .then(res => {
         successNotify(`Игра № ${res.data.games_id} была изменена!`);
         addLog(`Изменил игру № ${res.data.games_id}`);
      })
      .catch(err => console.log(err));

  }
  useEffect(() => {
    getOneNews();
  },[setItems]);
  if (items == null) return (
    <div>Отсутствует страница!</div>
  ) 
  return (
    <div className="pt-2">
    <Breadcrumb>
      <Link className="breadcrumb-item" to="/">
        Главная
      </Link>
      <Link className="breadcrumb-item" to="/games">
        Игры
      </Link>
      <Breadcrumb.Item active>Изменить игру</Breadcrumb.Item>
    </Breadcrumb>
      <div className="shadow-sm p-4 bg-white rounded">
        <h5 className="pb-2">Изменить игру</h5>
        <Form noValidate validated={validated}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Название игры</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Название игры"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <Form.Control.Feedback>
                Название заполнено
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните название
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Выберите изображение</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectFile}
              />
              <Form.Control.Feedback>Изображение выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label>Статус публикации</Form.Label>
              <Form.Select 
                aria-label="Default select example"
                defaultValue={published}
                onChange={e => setPublished(e.target.value)}
              >
                <option value={published}>Не публиковать игру</option>
                <option value="true">Опубликовать игру</option>
            </Form.Select>
            <Form.Control.Feedback>
              Статус публикации выбран
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Выберите статус публикации
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Жанр игры</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Жанр игры"
                value={typeGame}
                onChange={e => setTypeGame(e.target.value)}
              />
              <Form.Control.Feedback>
                Жанр игры заполнен
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните жанр игры
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Максимальное кол-во игроков</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Максимальное кол-во игроков"
                value={maxPlayers}
                onChange={e => setMaxPlayers(e.target.value)}
              />
              <Form.Control.Feedback>
                Максимальное кол-во игроков заполнено
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Заполните максимальное кол-во игроков
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Стоимость игры (страница с игрой)</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Стоимость игры (страница с игрой)"
                value={cost}
                onChange={e => setCost(e.target.value)}
              />
              <Form.Control.Feedback>
                Стоимость заполнена
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Заполните стоимость игры
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Стоимость игры</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Стоимость игры"
                value={costSmall}
                onChange={e => setCostSmall(e.target.value)}
              />
              <Form.Control.Feedback>
                Стоимость заполнена
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Заполните стоимость игры
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>Допустимый возраст</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Допустимый возраст"
                value={age}
                onChange={e => setAge(e.target.value)}
              />
              <Form.Control.Feedback>
                Допустимый возраст заполнен
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                  Заполните допустимый возраст
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Выберите трейлер</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectTrailer}
              />
              <Form.Control.Feedback>Трейлер выбран</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите трейлер
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Изображение в слайдер 1</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectPic1}
              />
              <Form.Control.Feedback>Изображение в слайдер 1 выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение в слайдер 1
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Изображение в слайдер 2</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectPic2}
              />
              <Form.Control.Feedback>Изображение в слайдер 2 выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение в слайдер 2
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Изображение в слайдер 3</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectPic3}
              />
              <Form.Control.Feedback>Изображение в слайдер 3 выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение в слайдер 4
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Изображение в слайдер 4</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectPic4}
              />
              <Form.Control.Feedback>Изображение в слайдер 4 выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение в слайдер 4
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Изображение в слайдер 5</Form.Label>
              <Form.Control 
                required
                type="file"
                onChange={selectPic5}
              />
              <Form.Control.Feedback>Изображение в слайдер 5 выбрано</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Выберите изображение в слайдер 5
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label>Описание игры</Form.Label>
              <Form.Control
                required
                as="textarea"
                style={{ height: '200px' }}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Заполните описание игры
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Button className="btn-bl" onClick={handleSubmit}><i className='bx bx-fw bx-check bx-flashing' ></i> Изменить игру</Button>
        </Form>
      </div>
    </div>
  );
}

export default (EditGamePage);