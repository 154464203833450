import React, { useEffect, useState, useContext } from 'react';
import {observer} from "mobx-react-lite";
import {Breadcrumb, InputGroup, Form, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import { API_URL } from '../../http';
import successNotify from '../success-notify';
import axios from 'axios';
import {Context} from '../../index';



const SettingsPage = () => {

  const [items, setItems] = useState([]);
  const {store} = useContext(Context);


  const [headerText, setHeaderText] = useState('');
  const [headerAltText, setHeaderAltText] = useState('');
  const [headerFullText, setHeaderFullText] = useState('');
  const [headerAllGames, setHeaderAllGames] = useState('');
  const [headerAllNews, setHeaderAllNews] = useState('');
  const [howToGameText1, setHowToGameText1] = useState('');
  const [howToGameText2, setHowToGameText2] = useState('');
  const [howToGameText3, setHowToGameText3] = useState('');
  const [howToGameText4, setHowToGameText4] = useState('');
  const [headerVkDecLink, setHeaderVkDecLink] = useState('');
  const [headerVkText, setHeaderVkText] = useState('');
  const [headerVkLink, setHeaderVkLink] = useState('');
  const [orgName, setOrgName] = useState('');
  const [innOrg, setInnOrg] = useState('');
  const [ogrnOrg, setOgrnOrg] = useState('');
  const [orgAddress, setOrgAddress] = useState('');
  const [orgEmail, setOrgEmail] = useState('');
  const [orgTimeJob, setOrgTimeJob] = useState('');
  const [orgPhone, setOrgPhone] = useState('');

  const editSettings = () => {
    const formData = new FormData()
    formData.append('headerText', headerText)
    formData.append('headerAltText', headerAltText)
    formData.append('headerFullText', headerFullText)
    formData.append('headerAllGames', headerAllGames)
    formData.append('headerAllNews', headerAllNews)
    formData.append('howToGameText1', howToGameText1)
    formData.append('howToGameText2', howToGameText2)
    formData.append('howToGameText3', howToGameText3)
    formData.append('howToGameText4', howToGameText4)
    formData.append('headerVkDecLink', headerVkDecLink)
    formData.append('headerVkText', headerVkText)
    formData.append('headerVkLink', headerVkLink)
    formData.append('orgName', orgName)
    formData.append('innOrg', innOrg)
    formData.append('ogrnOrg', ogrnOrg);
    formData.append('orgAddress', orgAddress)
    formData.append('orgEmail', orgEmail)
    formData.append('orgTimeJob', orgTimeJob)
    formData.append('orgPhone', orgPhone)

    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/s/edit', formData)
    .then(res => {
        addLog(`Обновил информацию в настройках`);
        successNotify(`Настройки были обновлены!`);
        getSettings();
    })
    .catch(err => console.log(err));

}

async function addLog(description) {
  const formData = new FormData()
  formData.append('username', store.user.email)
  formData.append('description', description)
  axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  axios.post(API_URL + '/l/create', formData)
  .then(res => {
  })
  .catch(err => console.log(err));
}

  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    if(data != null) {
      setHeaderText(data.headerText);
      setHeaderAltText(data.headerAltText);
      setHeaderFullText(data.headerFullText);
      setHeaderAllGames(data.headerAllGames);
      setHeaderAllNews(data.headerAllNews);
      setHowToGameText1(data.howToGameText1);
      setHowToGameText2(data.howToGameText2);
      setHowToGameText3(data.howToGameText3);
      setHowToGameText4(data.howToGameText4);
      setHeaderVkDecLink(data.headerVkDecLink);
      setHeaderVkText(data.headerVkText);
      setHeaderVkLink(data.headerVkLink);
      setOrgName(data.orgName);
      setInnOrg(data.innOrg);
      setOgrnOrg(data.ogrnOrg);
      setOrgAddress(data.orgAddress);
      setOrgEmail(data.orgEmail);
      setOrgTimeJob(data.orgTimeJob);
      setOrgPhone(data.orgPhone);
    }
    setItems(data);
  };

  useEffect(() => {
    getSettings();
  }, [setItems]);

    return (
      <div className="pt-2">
      <Breadcrumb>
        <Link className="breadcrumb-item" to="/">
          Главная
        </Link>
        <Breadcrumb.Item active>Настройки</Breadcrumb.Item>
      </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Настройки</h5>
          
          <h6 className="pb-2">Основные настройки</h6>
          <InputGroup className="mb-3">
            <InputGroup.Text id="icon_1">Заголовок главная</InputGroup.Text>
            <Form.Control
              placeholder="Введите значение..."
              aria-label="Введите значение..."
              aria-describedby="icon_1"
              value={headerText}
              onChange={e => setHeaderText(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="title_1">Выделение заголовка главная</InputGroup.Text>
            <Form.Control
              placeholder="Введите значение..."
              aria-label="Введите значение..."
              aria-describedby="title_1"
              value={headerAltText}
              onChange={e => setHeaderAltText(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="desc_1">Описание главная</InputGroup.Text>
            <Form.Control
              placeholder="Введите значение..."
              aria-label="Введите значение..."
              aria-describedby="desc_1"
              value={headerFullText}
              onChange={e => setHeaderFullText(e.target.value)}
            />
          </InputGroup>

          <InputGroup className="mb-3 mt-5">
            <InputGroup.Text id="icon_2">Описание все игры</InputGroup.Text>
            <Form.Control
              placeholder="Введите значение..."
              aria-label="Введите значение..."
              aria-describedby="icon_2"
              value={headerAllGames}
              onChange={e => setHeaderAllGames(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="title_2">Описание все новости</InputGroup.Text>
            <Form.Control
              placeholder="Введите значение..."
              aria-label="Введите значение..."
              aria-describedby="title_2"
              value={headerAllNews}
              onChange={e => setHeaderAllNews(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="desc_2">Описание шаг1</InputGroup.Text>
            <Form.Control
              placeholder="Введите описание..."
              aria-label="Введите описание..."
              aria-describedby="desc_2"
              value={howToGameText1}
              onChange={e => setHowToGameText1(e.target.value)}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text id="desc_2">Описание шаг 2</InputGroup.Text>
            <Form.Control
              placeholder="Введите описание..."
              aria-label="Введите описание..."
              aria-describedby="desc_22"
              value={howToGameText2}
              onChange={e => setHowToGameText2(e.target.value)}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text id="desc_23">Описание шаг 3</InputGroup.Text>
            <Form.Control
              placeholder="Введите описание..."
              aria-label="Введите описание..."
              aria-describedby="desc_23"
              value={howToGameText3}
              onChange={e => setHowToGameText3(e.target.value)}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text id="desc_24">Описание шаг 4</InputGroup.Text>
            <Form.Control
              placeholder="Введите описание..."
              aria-label="Введите описание..."
              aria-describedby="desc_24"
              value={howToGameText4}
              onChange={e => setHowToGameText4(e.target.value)}
            />
          </InputGroup>

          <InputGroup className="mb-3 mt-5">
            <InputGroup.Text id="icon_3">Заголовок ВК</InputGroup.Text>
            <Form.Control
              placeholder="Введите значение..."
              aria-label="Введите значение..."
              aria-describedby="icon_3"
              value={headerVkDecLink}
              onChange={e => setHeaderVkDecLink(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="title_3">Описание ВК</InputGroup.Text>
            <Form.Control
              placeholder="Введите значение..."
              aria-label="Введите значение..."
              aria-describedby="title_3"
              value={headerVkText}
              onChange={e => setHeaderVkText(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="desc_3">Ссылка ВК</InputGroup.Text>
            <Form.Control
              placeholder="Введите значение..."
              aria-label="Введите значение..."
              aria-describedby="desc_3"
              value={headerVkLink}
              onChange={e => setHeaderVkLink(e.target.value)}
            />
          </InputGroup>
          <h6 className="pb-2 mt-5">Контакты</h6>


          <InputGroup className="mb-3">
            <InputGroup.Text id="org">Наименование организации</InputGroup.Text>
            <Form.Control
              placeholder="Введите наименование организации..."
              aria-label="Введите наименование организации..."
              aria-describedby="org"
              value={orgName}
              onChange={e => setOrgName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="inn">ИНН организации</InputGroup.Text>
            <Form.Control
              placeholder="Введите ИНН организации..."
              aria-label="Введите ИНН организации..."
              aria-describedby="inn"
              value={innOrg}
              onChange={e => setInnOrg(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="ogrn">ОГРН организации</InputGroup.Text>
            <Form.Control
              placeholder="Введите ОГРН организации..."
              aria-label="Введите ОГРН организации..."
              aria-describedby="ogrn"
              value={ogrnOrg}
              onChange={e => setOgrnOrg(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="address">Адрес организации</InputGroup.Text>
            <Form.Control
              placeholder="Введите адрес организации..."
              aria-label="Введите адрес организации..."
              aria-describedby="address"
              value={orgAddress}
              onChange={e => setOrgAddress(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="director">E-Mail для обращений</InputGroup.Text>
            <Form.Control
              placeholder="Введите E-Mail..."
              aria-label="Введите E-Mail..."
              aria-describedby="director"
              value={orgEmail}
              onChange={e => setOrgEmail(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="phone">Номер телефона</InputGroup.Text>
            <Form.Control
              placeholder="Введите номер телефона..."
              aria-label="Введите номер телефона..."
              aria-describedby="phone"
              value={orgPhone}
              onChange={e => setOrgPhone(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="email">Время работы</InputGroup.Text>
            <Form.Control
              placeholder="Введите значение..."
              aria-label="Введите значение..."
              aria-describedby="email"
              value={orgTimeJob}
              onChange={e => setOrgTimeJob(e.target.value)}
            />
          </InputGroup>
          <Button onClick={() => editSettings()} className="btn-bl"><i className='bx bx-fw bx-check bx-flashing' ></i> Обновить данные</Button>






        </div>
      </div>
    );
};

export default observer(SettingsPage);