//import { observer } from "mobx-react-lite";
import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import IndexPage from './pages/index-page';
import NewsPage from "./components/pages/news-page";
import AddNewsPage from "./components/pages/addnews-page";
import EditNewsPage from "./components/pages/editnews-page";
import StaticPage from "./components/pages/static-page";
import ModulePage from "./components/pages/module-page";
import SettingsPage from "./components/pages/settings-page";
import UsersPage from "./components/pages/users-page";
import AboutPage from "./components/pages/about-page";
import SupportPage from "./components/pages/support-page";
import UserChart from './components/users-chart';
import DashboardPage from './components/pages/dashboard-page';
import ErrorPage from './components/pages/error-page';
import ProfilePage from "./components/pages/profile-page";
import AddPagesPage from "./components/pages/addpages-page";
import EditStaticPage from "./components/pages/editstatic-page";
import LogsPage from './components/pages/logs-page';
import TicketsPage from "./components/pages/tickets-page";
import GamePage from "./components/pages/game-page";
import AddGamePage from "./components/pages/addgame-page";
import EditGamePage from "./components/pages/editgame-page";
import CustomPage from "./components/pages/custom-page";

function App() {
  return (
    <Router>
    <Routes>
      <Route element={<IndexPage />}>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/add" element={<AddNewsPage />} />
        <Route path="/news/edit/:id" element={<EditNewsPage />} />
        <Route path="/pages" element={<StaticPage />} />
        <Route path="/pages/add" element={<AddPagesPage />} />
        <Route path="/pages/edit/:id" element={<EditStaticPage />} />
        <Route path="/module" element={<ModulePage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/profile" element={<ProfilePage />}/>
        <Route path="/logs" element={<LogsPage />}/>
        <Route path="/tickets" element={<TicketsPage />}/>
        <Route path="/games" element={<GamePage/>}/>
        <Route path="/games/add" element={<AddGamePage />} />
        <Route path="/games/edit/:id" element={<EditGamePage />} />
        <Route path="/custom" element={<CustomPage />} />
      </Route>
      <Route element={<IndexPage />}>
        <Route path="*" element={<ErrorPage />}></Route>
      </Route>
    </Routes>
  </Router>)
}

export default App;