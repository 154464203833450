import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Breadcrumb} from 'react-bootstrap';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import UserChart from '../users-chart';
import SupportChart from '../support-chart';
import { API_URL } from '../../http';




const DashboardPage = () => {

  const [logs, setLogs] = useState([]);
  const [tickets, setTickets] = useState('');
  const [pages, setPages] = useState('');
  const [news, setNews] = useState('');

  const getLogs = async () => {
    const res = await fetch(
      API_URL + `/l`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setLogs(data);
  };

  const getTickets = async () => {
    const res = await fetch(
      API_URL + `/t/all`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setTickets(data);
  };

  const getPages = async () => {
    const res = await fetch(
      API_URL + `/p/count`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setPages(data);
  };

  const getNews = async () => {
    const res = await fetch(
      API_URL + `/n/count`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setNews(data);
  };

  useEffect(() => {
    getLogs();
  }, [setLogs]);

  useEffect(() => {
    getTickets();
  }, [setTickets]);

  useEffect(() => {
    getPages();
  }, [setPages]);

  useEffect(() => {
    getNews();
  }, [setNews]);


    return (
      <div className="pt-2">
        <Breadcrumb>
          <Breadcrumb.Item active>Главная</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row row-sm mb-2">
          <div className="col-md-12">
            <div className="shadow-sm p-4 bg-white rounded">
              <h5 className="pb-2">Статистика</h5>
              <div className="row d-flex justify-content-around">
                  <div className="col-xl-3 col-lg-6 mb-2 mb-md-0">
                    <div className="card btn-panel" style={{borderRadius: '10px'}}>
                      <div className="card-body">
                        <div className="d-flex justify-content-between px-2">
                          <div className="align-self-center align-middle">
                          <span className="align-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" style={{color: '#fff'}} width={33} height={33} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                              <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                              <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                            </svg>
                          </span>
                          </div>
                          <div className="text-start">
                            <h4 className="m-0 text-white p-0">50</h4>
                            <p className="m-0 text-white p-0">Просмотров</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 mb-2 mb-md-0">
                    <div className="card btn-panel" style={{borderRadius: '10px'}}>
                      <div className="card-body">
                        <div className="d-flex justify-content-between px-2">
                          <div className="align-self-center align-middle">
                          <span className="align-middle"> 
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-news" style={{color: '#fff'}} width={33} height={33} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11"></path>
                              <path d="M8 8l4 0"></path>
                              <path d="M8 12l4 0"></path>
                              <path d="M8 16l4 0"></path>
                            </svg>
                          </span>
                          </div>
                          <div className="text-start">
                            <h4 className="m-0 text-white p-0">{news == '' || news == null || news == undefined ? `--` : news}</h4>
                            <p className="m-0 text-white p-0">Новостей</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 mb-2 mb-md-0">
                    <div className="card btn-panel" style={{borderRadius: '10px'}}>
                      <div className="card-body">
                        <div className="d-flex justify-content-between px-2">
                          <div className="align-self-center align-middle">
                          <span className="align-middle"> 
                            <svg xmlns="http://www.w3.org/2000/svg" style={{color: '#fff'}} className="icon icon-tabler icon-tabler-notebook" width={33} height={33} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18"></path>
                              <path d="M13 8l2 0"></path>
                              <path d="M13 12l2 0"></path>
                            </svg>
                          </span>
                          </div>
                          <div className="text-start">
                            <h4 className="m-0 text-white p-0">{pages == '' || pages == null || pages == undefined ? `--` : pages}</h4>
                            <p className="m-0 text-white p-0">Страниц</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 mb-2 mb-md-0">
                    <div className="card btn-panel" style={{borderRadius: '10px'}}>
                      <div className="card-body">
                        <div className="d-flex justify-content-between px-2">
                          <div className="align-self-center align-middle">
                            <span className="align-middle"> 
                            <svg xmlns="http://www.w3.org/2000/svg" style={{color: '#fff'}} className="icon icon-tabler icon-tabler-help-hexagon" width={33} height={33} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"></path>
                              <path d="M12 16v.01"></path>
                              <path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
                            </svg>
                          </span>
                          </div>
                          <div className="text-start">
                            <h4 className="m-0 text-white p-0">{tickets == '' || tickets == null || tickets == undefined ? `--` : tickets}</h4>
                            <p className="m-0 text-white p-0">Тикетов</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-sm mb-2">
          <div className="col-xxl-5 col-xl-12 mt-2 mt-xxl-0">
            <div className="shadow-sm p-4 bg-white rounded">
                <h5 className="pb-3">С возвращением!</h5>
                <p>Добро пожаловать снова в панель управления сайтом, давайте посмотрим что изменилось с момента последнего входа?</p>
              </div>
              <div className="shadow-sm p-4 bg-white rounded mt-2">
                  <h5 className="pb-4">Заявки в поддержку</h5>
                  <div className="d-flex justify-content-center">
                    <SupportChart />
                  </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-12">
              <div className="shadow-sm p-4 bg-white rounded">
                <h5 className="pb-3">Просмотры сайта</h5>
                <UserChart />
              </div>
            </div>
        </div>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Последние события</h5>

          <table className="admintable mb-2">
            <thead>
              <tr>
                <th>Дата и время</th>
                <th>Пользователь</th>
                <th>Событие</th>
              </tr>
            </thead>
            <tbody>
            {logs.map((logs) => {
              return (
                <tr key={logs._id}>
                  <td data-label="Дата и время">{logs.logDate}</td>
                  <td data-label="Пользователь">{logs.username}</td>
                  <td data-label="Событие">{logs.description}</td>
                </tr>
              )
            })}
            </tbody>
          </table> 
        </div>
      </div>
    );
};

export default observer(DashboardPage);
