import React, {useContext, useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Table} from 'react-bootstrap';
import 'boxicons/css/boxicons.css';
import 'boxicons';
import {Breadcrumb} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import {Modal, Button} from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import '../../style.css';
import successNotify from '../success-notify';
import errorNotify from '../error-notify';
import {Context} from '../../index';
import { API_URL } from '../../http';
import { IMG_URL } from '../../http';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReactPlayer from 'react-player'
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form'

const MediaPage = () => {
  const [bg, setBg] = useState([]);
  const [showModalBg, setShowModalBg] = useState(false);
  const [showModalBgDelete, setShowModalBgDelete] = useState(false);

  const [items, setItems] = useState([]);
  const [title, setTitle] = useState('');
  const [published, setPublished] = useState(false);
  const [showModalMedia, setShowModalMedia] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [deleteTitle, setDeleteTitle] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [deleteLink, setDeleteLink] = useState('');
  const [description, setDescription] = useState('');
  const [videoTitle, setVideoTitle] = useState('Выбрать файл...');
  const {store} = useContext(Context);
  const [file, setFile] = useState(null);
  const selectFile = e => {
    setFile(e.target.files[0])
    if (e.target.files[0] == null){
        setVideoTitle(null);
    }
    else setVideoTitle(e.target.files[0].name);
  }

  const handleCloseModalMedia = () => setShowModalMedia(false);
  const handleShowModalMedia = () => {
    setShowModalMedia(true);
  }

  const handleCloseModalBg = () => setShowModalBg(false);
  const handleShowModalBg = () => {
    setShowModalBg(true);
  }

  const handleCloseModalDelete = () => setShowModalDelete(false);
  const handleShowModalDelete = (did, dtitle, dlink) => {
    setDeleteId(did);
    setDeleteTitle(dtitle);
    setDeleteLink(dlink);
    setShowModalDelete(true)
  }

  const handleCloseModalDeleteBg = () => setShowModalBgDelete(false);
  const handleShowModalDeleteBg = (did, dlink) => {
    setDeleteId(did);
    setDeleteLink(dlink);
    setShowModalBgDelete(true)
  }

  const getMediaFiles = async () => {
    const res = await fetch(
      API_URL + `/c/all`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setItems(data);
  };

  const getBg = async () => {
    const res = await fetch(
      API_URL + `/c/b/all`, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
     });
    const data = await res.json();
    setBg(data);
  };

  async function toggleUnPublish(id, title) {
    const formData = new FormData()
    formData.append('custom_id', id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/unpublish', formData)
    .then(res => {
      successNotify(`Слайд ${title} был обновлен!`);
      addLog(`Изменил статус публикации слайда ${title}`);
      getMediaFiles();
    })
    .catch(err => console.log(err));
  }

  async function togglePublish(id, title) {
    const formData = new FormData()
    formData.append('custom_id', id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/publish', formData)
    .then(res => {
      successNotify(`Слайд ${title} был обновлен!`);
      addLog(`Изменил статус публикации слайда ${title}`);
      getMediaFiles();
    })
    .catch(err => console.log(err));
  }

  async function toggleUnPublishBg(id) {
    const formData = new FormData()
    formData.append('bg_id', id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/b/unpublish', formData)
    .then(res => {
      successNotify(`Фоновое изображение было обновлено!`);
      addLog(`Изменил статус публикации фонового изображения`);
      getBg();
    })
    .catch(err => console.log(err));
  }

  async function togglePublishBg(id) {
    const formData = new FormData()
    formData.append('bg_id', id)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/b/publish', formData)
    .then(res => {
      successNotify(`Фоновое изображение было обновлено!`);
      addLog(`Изменил статус публикации фонового изображения`);
      getBg();
    })
    .catch(err => console.log(err));
  }

  useEffect(() => {
    getMediaFiles();
  }, [setItems]);

  useEffect(() => {
    getBg();
  }, [setBg]);

  async function addLog(description) {
    const formData = new FormData()
    formData.append('username', store.user.email)
    formData.append('description', description)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/l/create', formData)
    .then(res => {
      console.log(res);
    })
    .catch(err => console.log(err));
  }

  const addCustom = () => {
    if (title == '' || file == '' || description == '') {
        return errorNotify(`Необходимо заполнить все поля!`);
    }
    if (file == null) {
        return errorNotify(`Необходимо выбрать фото слайда для загрузки!`);
    }
    const formData = new FormData()
    formData.append('title', title)
    formData.append('description', description)
    formData.append('img', file)
    formData.append('published', published)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/create', formData)
    .then(res => {
       successNotify(`Слайд ${title} был добавлен!`);
       addLog(`Добавил слайд ${title}`);
       setFile(null);
       setTitle('');
       setDescription('');
       getMediaFiles();
       handleCloseModalMedia();
    })
    .catch(err => console.log(err));
}

const addBg = () => {
  if (file == '') {
      return errorNotify(`Необходимо заполнить все поля!`);
  }
  if (file == null) {
      return errorNotify(`Необходимо выбрать изображение для загрузки!`);
  }
  const formData = new FormData()
  formData.append('img', file)
  formData.append('published', published)
  axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  axios.post(API_URL + '/c/b/create', formData)
  .then(res => {
     successNotify(`Фоновое изображение было добавлено!`);
     addLog(`Добавил фоновое изображение`);
     setFile(null);
     getBg();
     handleCloseModalBg();
  })
  .catch(err => console.log(err));
}

const deleteMediaFile = (id, link) => {
    const formData = new FormData()
    formData.append('_id', id)
    formData.append('link', link)
    axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.post(API_URL + '/c/delete', formData)
    .then(res => {
       successNotify(`Слайд ${deleteTitle} был удален!`);
       addLog(`Удалил слайд ${deleteTitle}`);
       setDeleteId('');
       setDeleteTitle('');
       setDeleteLink('');
       getMediaFiles();
       handleCloseModalDelete();
    })
    .catch(err => console.log(err));
}

const deleteBg = (id, link) => {
  const formData = new FormData()
  formData.append('_id', id)
  formData.append('link', link)
  axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  axios.post(API_URL + '/c/b/delete', formData)
  .then(res => {
     successNotify(`Фоновое изображение было удалено!`);
     addLog(`Удалил фоновое изображение`);
     setDeleteId('');
     setDeleteLink('');
     getBg();
     handleCloseModalDeleteBg();
  })
  .catch(err => console.log(err));
}

    return (
      <div className="pt-2">

<Modal
          show={showModalBg}
          onHide={handleCloseModalBg}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Добавление изображения</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control 
              required
              type="file"
              onChange={selectFile}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalBg}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => addBg()}>
              <i className='bx bx-check bx-fw'></i> Добавить
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalMedia}
          onHide={handleCloseModalMedia}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Добавление слайда</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
                placeholder="Введите заголовок слайда"
                aria-label="Введите заголовок слайда"
                aria-describedby="basic-addon2"
                value={title}
                onChange={e => setTitle(e.target.value)}
                className="mb-2"
              />
              <Form.Control
                placeholder="Введите описание слайда"
                aria-label="Введите описание слайда"
                aria-describedby="basic-addon2"
                value={description}
                onChange={e => setDescription(e.target.value)}
                className="mb-2"
              />
              <Form.Control 
                required
                type="file"
                onChange={selectFile}
              />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalMedia}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => addCustom()}>
              <i className='bx bx-check bx-fw'></i> Добавить
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalDelete}
          onHide={handleCloseModalDelete}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Удаление слайда</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы действительно хотите удалить слайд <strong>{deleteTitle}</strong>? После подтверждения, данное действие отменить будет невозможно.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalDelete}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => deleteMediaFile(deleteId, deleteLink)}>
              <i className='bx bx-check bx-fw'></i> Удалить
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalBgDelete}
          onHide={handleCloseModalDeleteBg}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Удаление изображения</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы действительно хотите удалить изображение? После подтверждения, данное действие отменить будет невозможно.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" className="outline-btn-bl" onClick={handleCloseModalDeleteBg}>
              <i className='bx bx-layer-minus bx-fw' ></i> Отмена
            </Button>
            <Button variant="primary" className="btn-bl" onClick={() => deleteBg(deleteId, deleteLink)}>
              <i className='bx bx-check bx-fw'></i> Удалить
            </Button>
          </Modal.Footer>
        </Modal>

        <Breadcrumb>
          <Link to="/" className="breadcrumb-item">
            Главная
          </Link>
          <Breadcrumb.Item active>Кастомизация</Breadcrumb.Item>
        </Breadcrumb>
        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Кастомизация</h5>
          <a onClick={handleShowModalMedia}><Button className="mb-2 btn-bl"><i className='bx bx-fw bx-brush' ></i> Добавить слайд</Button></a>
            <Row xs={1} md={3} className="g-4">
            {items.map((item) => {
                return (
                <Col key={item._id}>
                    <Card>
                        <Card.Header>
                            <Nav>
                                <Nav.Item>
                                    <button onClick={() => handleShowModalDelete(item._id, item.title, item.link)} type="button" id="button-addon2" className="outline-btn-bl btn btn-outline-primary"><i className="bx bx-fw bx-lock-open-alt"></i> Удалить</button>
                                </Nav.Item>
                                <Nav.Item className="ms-xl-2 ms-lg-0 mt-lg-2 mt-md-2 ms-md-2 ms-sm-2 ms-2 mt-xl-0 mt-lg-0">
                                    <button onClick={() => item.published === true ? toggleUnPublish(item._id, item.title) : togglePublish(item._id, item.title)} type="button" id="button-addon2" className="outline-btn-bl btn btn-outline-primary mr-2"><i className="bx bx-fw bx-lock-open-alt"></i> {item.published === true ? `Снять с публикации` : `Опубликовать`}</button>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Img style={{width: '270px', objectFit: 'cover', marginLeft: 'auto', marginRight: 'auto'}} className='mt-2 mb-2' src={IMG_URL + '/' + item.img} />
                        <Card.Body>
                            <Card.Title>{item.title}</Card.Title>
                            <p>{item.description}</p>
                        </Card.Body>
                    </Card>
                </Col>
                )
                })}
            </Row>
        </div>

        <div className="shadow-sm p-4 bg-white rounded">
          <h5 className="pb-2">Фоновые изображения</h5>
          <a onClick={handleShowModalBg}><Button className="mb-2 btn-bl"><i className='bx bx-fw bx-brush' ></i> Добавить изображение</Button></a>
            <Row xs={1} md={3} className="g-4">
            {bg.map((item) => {
                return (
                <Col key={item._id}>
                    <Card>
                        <Card.Header>
                            <Nav>
                                <Nav.Item>
                                    <button onClick={() => handleShowModalDeleteBg(item._id, item.title, item.link)} type="button" id="button-addon2" className="outline-btn-bl btn btn-outline-primary"><i className="bx bx-fw bx-lock-open-alt"></i> Удалить</button>
                                </Nav.Item>
                                <Nav.Item className="ms-xl-2 ms-lg-0 mt-lg-2 mt-md-2 ms-md-2 ms-sm-2 ms-2 mt-xl-0 mt-lg-0">
                                    <button onClick={() => item.published === true ? toggleUnPublishBg(item._id) : togglePublishBg(item._id)} type="button" id="button-addon2" className="outline-btn-bl btn btn-outline-primary mr-2"><i className="bx bx-fw bx-lock-open-alt"></i> {item.published === true ? `Снять с публикации` : `Опубликовать`}</button>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Img style={{width: '270px', objectFit: 'cover', marginLeft: 'auto', marginRight: 'auto'}} className='mt-2 mb-2' src={IMG_URL + '/' + item.img} />
                    </Card>
                </Col>
                )
                })}
            </Row>
        </div>
      </div>
    );
};

export default MediaPage;